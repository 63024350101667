<template>
	<div id="effe2-loader-inapp" v-show="display_loader === true">
		
		<img src="/assets/loader.gif" /><br/>
		Please wait
	</div>
</template>

<script>

export default {
	name: 'Modal',
	components: {

	},
	props: {

	},
	data: function (){
		return {
			
			display_loader: false,
		}
	},
	created: function() {
		
		var $this = this;
		
		this.$root.$on('openLoader', function() {
			$this.display_loader = true; 
		});
		
		this.$root.$on('closeLoader', function() {
			$this.display_loader = false; 
		});
	},
	methods: {
		
		
	}

}
</script>


import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueRouter)
Vue.use(VueCompositionAPI)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Dm.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

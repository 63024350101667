<template>
	<div class="effe2-modal-background" v-show="display_modal === true && wallet_address === null">
		<div class="effe2-modal" style="padding-bottom: 50px;">
			<div class="effe2-modal-title" style="margin-bottom: 50px;">Connect wallet</div>
			
			<span class="effe2-button-black" style="box-shadow: 3px 3px 3px black;" @click="connect_wallet('phantom')">
				<img src="/assets/images/wallet/phantom.jpg" class="effe2_wallet_img" /> Phantom
			</span>
			<span class="effe2-button-black" style="box-shadow: 3px 3px 3px black; margin-left: 20px;" @click="connect_wallet('solflare')">
				<img src="/assets/images/wallet/solflare.svg" class="effe2_wallet_img" /> Solflare
			</span>
			<span class="effe2-button-black" style="box-shadow: 3px 3px 3px black; margin-left: 20px;" @click="connect_wallet('sollet')">
				<img src="/assets/images/wallet/sollet.jpg" class="effe2_wallet_img" /> Sollet
			</span>
			<span class="effe2-button-black" style="box-shadow: 3px 3px 3px black; margin-left: 20px;" @click="connect_wallet('slope')">
				<img src="/assets/images/wallet/slope.png" class="effe2_wallet_img" /> Slope
			</span>
			<br/>
			<br/>
			<br/>
			<br/>
			<span class="effe2-button"  @click="display_modal = false">Close</span>
		</div>
	</div>
</template>

<script>

import {connexion_with_sollet, connexion_with_slope} from '@/libs/wallet';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

export default {
	name: 'Header',
	components: {

	},
	props: {

	},
	data: function (){
		return {
			
			display_modal: false,
			wallet_address: null,
		}
	},
	created: function() {
		
		var $this = this;
		
		this.$root.$on('openPopup', function() {
			$this.display_modal = true; 
		});
	},
	methods: {
		
		connect_wallet : async function (wallet_name){

			var component_vue = this;

			if(wallet_name == 'phantom' && getProviderPhantom() == undefined)
				return;

			else if(wallet_name == 'solflare' && getProviderSolflare() == undefined)
				return;

			else if(wallet_name == 'sollet' ){

				var sollet_wallet = await connexion_with_sollet();

				if(sollet_wallet != null && sollet_wallet.connected === false)
					return;

				return component_vue.connexion_wallet_connect(sollet_wallet.publicKey.toString());
			}

			else if(wallet_name == 'slope'){

				var slope_wallet = await connexion_with_slope();

				if (slope_wallet === false){
					window.open('https://slope.finance', '_blank');
					return;
				}
				else{

					return component_vue.connexion_wallet_connect(slope_wallet.publicKey.toString());
				}
			}

			var provider_name = '';

			if(wallet_name == 'phantom')
				provider_name = window.solana;

			else if(wallet_name == 'solflare')
				provider_name = window.solflare;

			provider_name.connect();

			provider_name.on("connect", () => {

				var wallet_address = '';

				if(wallet_name == 'phantom')
					wallet_address = provider_name.publicKey.toBase58();
				else if(wallet_name == 'solflare' || wallet_name == 'sollet')
					wallet_address = provider_name.publicKey.toString();

				return component_vue.connexion_wallet_connect(wallet_address);
			});
		},
		
		connexion_wallet_connect: function(wallet_address) {
			
			this.wallet_address = wallet_address;
			this.$root.wallet_address = wallet_address;
			
			this.$root.$emit('walletConnected');
			
			
		}
	}

}

const getProviderPhantom = () => {

	if ("solana" in window) {

		const provider = window.solana;

		if (provider.isPhantom) {

			return provider;

		}

	}

	window.open("https://phantom.app/", "_blank");

};

const isSolflareInstalled = window.solflare && window.solflare.isSolflare;

const getProviderSolflare = () => {

	if ('solflare' in window) {

		const provider = window.solflare;

		if (provider.isSolflare) {

			return provider;

		}
	}
	window.open('https://solflare.com', '_blank');
};
</script>


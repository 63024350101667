import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Nl2br from 'vue-nl2br'
import $ from 'jquery'
import 'dtoaster/dist/dtoaster.css'
import DToaster from 'dtoaster'
import ToasterPresets from "../public/assets/toaster/toast_presets.json"
import VueCompositionAPI from '@vue/composition-api'
import VueCookie from 'vue-cookie'

Vue.component('nl2br', Nl2br)

Vue.config.productionTip = false;

Vue.use(DToaster, {
  presets: ToasterPresets,
  position: 'top-right',
  containerOffset: '45px',
});
Vue.use(VueCompositionAPI);
Vue.use(VueCookie);

Vue.use(require('vue-moment'));	

new Vue({
  data: {

    get_project_ready: null,
    wallet_address: null,
    project: null,
    modal: {
		
		title: '',
		text: '',
	},
  },
  created: function(){
    
  },
  methods: {

    
    
  },
  watch:{
    
  },
  router,
  render: h => h(App)
}).$mount('#app')

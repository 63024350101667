<template>
	<div id="app">
		<div id="wrapper">
			<div id="page" class="clearfix">
				
				<ConnectProject></ConnectProject>
				<Wallet></Wallet>
				<Modal></Modal>
				<Loader></Loader>
				<router-view/>
				
			</div>
			<!-- /#page -->

			
		</div>
    
	</div>
</template>

<script>

import Header from './components/Header.vue'
import Wallet from './components/Wallet.vue'
import Modal from './components/Modal.vue'
import Loader from './components/Loader.vue'
import ConnectProject from './components/ConnectProject.vue'

export default {
	name: 'App',
	components: {
		Modal,
		Loader,
		Wallet,
		ConnectProject
	},
	data: function() {
		return {
			
			
		}

	},
	computed: {

	},
	mounted: function(){


	},
}

</script>

<style>

</style>

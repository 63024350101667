<template>
	<div class="effe2-modal-background" v-show="display_modal === true">
		<div class="effe2-modal" style="padding-bottom: 50px;">
			<div class="effe2-modal-title" style="margin-bottom: 50px;">{{ $root.modal.title }}</div>
			
			{{ $root.modal.text }}<br/><br/>
			
			<br/>
			<br/>
			<span class="effe2-button"  @click="display_modal = false">Close</span>
			
		</div>
	</div>
</template>

<script>

export default {
	name: 'Modal',
	components: {

	},
	props: {

	},
	data: function (){
		return {
			
			display_modal: false,
		}
	},
	created: function() {
		
		var $this = this;
		
		this.$root.$on('openModal', function() {
			$this.display_modal = true; 
		});
	},
	methods: {
		
		
	}

}
</script>

